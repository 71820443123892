import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

axios.defaults.withCredentials = true

if (location.hostname == 'daishohr.daishoseiki.com') {
	axios.defaults.baseURL = 'https://daishohr.daishoseiki.com/personevalution-api'
} else if (location.hostname == 'testhr-admin.daishoseiki.com') {
	axios.defaults.baseURL = 'http://testhr-admin.daishoseiki.com/personevalution-api'
} else if (location.hostname == '160.251.50.98') {
	axios.defaults.baseURL = 'http://160.251.50.98/personevalution-api'
} else {
	axios.defaults.baseURL = 'http://localhost/personevalution-api'
}
axios.interceptors.request.use(function (config) {
	if (store.getters.token) {
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.token
		config.headers.Authorization = 'Bearer ' + store.getters.token
	} else {
		axios.defaults.headers.common['Authorization'] = ''
		config.headers.Authorization = ''
	}
	return config
}, function (error) {
	return Promise.reject(error)
})

createApp(App).use(store).use(router, axios).mount('#app')
